<template>
  <CRow>
    <CCol col="12" class="mb-2">
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/notification/0')"
      >
        Nueva notificación
      </CButton>
    </CCol>

    <CCol col="12">
      <CCard>
        <CCardHeader> Notificaciones </CCardHeader>
        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
            clickable-rows
            @row-clicked="rowClicked"
          >
          </CDataTable>

          <CPagination
            v-if="!loading"
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("notifications");

export default {
  name: "Notifications",
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "title", label: "Titulo", _classes: "font-weight-bold" },
        { key: "role", label: "Rol" },
        { key: "message", label: "Mensaje" },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
    };
  },

  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },

  methods: {
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    rowClicked(item, index) {
      this.$router.push({ path: `notification/${item.id}` });
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },
  },
};
</script>
